import { isEmptyObject, isUndefined } from '../libraries/app.library';
import { env } from '../config/constants.config';

/**
 * Returns the number of times the SDK has been loaded into the browser.
 */
export const getSDKLoadCount = (): number => {
  const count = window.__PushEngageSdkLoadCount || 0;

  return (window.__PushEngageSdkLoadCount = count + 1);
};

/**
 * The DOMContentLoaded event fires when the HTML document has been completely
 * parsed, and all deferred scripts (<script defer src="…"> and
 * <script type="module">) have downloaded and executed. It doesn't wait for other
 * things like images, subframes, and async scripts to finish loading.
 */
export const isDOMContentLoaded = (): Promise<void> => {
  return new Promise<void>(resolve => {
    if (document.readyState === 'loading') {
      // If the DOM is still loading, add an event listener for DOMContentLoaded
      document.addEventListener('DOMContentLoaded', () => resolve());
    } else {
      // If the DOM has already loaded, resolve the promise immediately
      resolve();
    }
  });
};

export const getSDKInitOptions = (commands: any, v1Commands = []) => {
  if (!commands || !Array.isArray(commands)) {
    return undefined;
  }

  // Process the SDK commands
  let sdkInitOptions: TSDKInitOptions | undefined;

  for (let i = 0; i < commands.length; i += 1) {
    const command = commands[i];

    if (Array.isArray(command) && command.length > 0) {
      const [commandName, commandOptions] = command;

      if (commandName === 'init' && typeof commandOptions === 'object') {
        sdkInitOptions = commandOptions;
        break;
      }
    }
  }

  /**
   * In case of new or legacy SDK initialization options, the init options should
   * always include the appId. If the appId is not present, it should return undefined,
   * and there is no need to proceed further with the processing.
   */
  if (!sdkInitOptions) {
    return undefined;
  }

  /**
   * This variable is used to check if the SDK is initialized on a sub-domain in the
   * legacy SDK.
   */
  if (!isUndefined((<any>window).dialog_box)) {
    sdkInitOptions.isSubscriptionOnSubDomain = true;
  }

  if (!sdkInitOptions.isLegacySDK) {
    return sdkInitOptions;
  }

  // If the v1 commands are not provided.
  if (!Array.isArray(v1Commands)) {
    return sdkInitOptions;
  }

  // Process the v1 SDK commands
  let isLegacyInitOptionsFound = false;

  for (let i = 0; i < v1Commands.length; i += 1) {
    const command: any = v1Commands[i];

    if (Array.isArray(command) && command.length > 0) {
      const [commandName, commandOptions] = command;

      if (commandName === 'init') {
        isLegacyInitOptionsFound = true;

        if (typeof commandOptions === 'string' || Array.isArray(commandOptions)) {
          sdkInitOptions.segment = commandOptions;
        }
      }

      if (commandName === 'config' && typeof commandOptions === 'object') {
        const serviceWorkerConfig: any = {};

        if (!isUndefined(commandOptions.worker)) {
          serviceWorkerConfig.path = commandOptions.worker;
        }

        if (!isUndefined(commandOptions.scope)) {
          serviceWorkerConfig.scope = commandOptions.scope;
        }

        if (!isUndefined(commandOptions.workerStatus)) {
          serviceWorkerConfig.status = commandOptions.workerStatus;
        }

        if (!isEmptyObject(serviceWorkerConfig)) {
          sdkInitOptions.serviceWorker = serviceWorkerConfig;
        }
      }
    }
  }

  if (isLegacyInitOptionsFound) {
    return sdkInitOptions;
  }

  return {
    ...sdkInitOptions,
    /**
     * If the init command is not provided in the legacy SDK, the subscription
     * will be disabled by default.
     */
    disabledDefaultPrompt: true,
  };
};

export const isHostPushEngageSubDomain = (subDomain: string): boolean => {
  return `${subDomain}.${env.__DOMAIN_NAME__}` === location.host;
};

export const getColorTheme = (color: string): string => {
  // Remove the "#" symbol from the color string
  const hex = color.replace('#', '');

  // Convert the hex value to RGB values
  const red = parseInt(hex.substring(0, 2), 16);
  const green = parseInt(hex.substring(2, 4), 16);
  const blue = parseInt(hex.substring(4, 6), 16);

  // Calculate the luminance of the color
  const luminance = (0.299 * red + 0.587 * green + 0.114 * blue) / 255;

  // Determine the color theme based on the luminance
  return luminance > 0.5 ? 'light' : 'dark';
};

export const removeSubscriberIdFromTag = (tag: string): string => {
  if (!tag.includes('-')) {
    return tag;
  }

  const lastIndex = tag.lastIndexOf('-');
  const result = tag.substring(0, lastIndex);

  return result;
};

export const sleep = (delay: number) => new Promise(resolve => setTimeout(resolve, delay));
