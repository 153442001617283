import log from '../../libraries/log.library';
import { isPushNotificationSupported as isPushNotificationSupportedUtil } from '../../utils/webPush.util';
import { isBrowser, isPrivateBrowser } from '../../libraries/browser.library';

export const handlePushEvent = (...args: any[]) => {
  for (let i = 0; i < args.length; i++) {
    const event = args[i];

    if (typeof event === 'function') {
      try {
        event();
      } catch (error) {
        log.error(error);
      }
    }
  }
};

export const isPushNotificationSupported = (): Promise<boolean> => {
  return new Promise(resolve => {
    const isSupported = isPushNotificationSupportedUtil();

    if (!isSupported || !isBrowser()) {
      resolve(false);
    }

    isPrivateBrowser()
      .then(isPrivate => {
        if (isPrivate) {
          resolve(false);
        } else {
          resolve(true);
        }
      })
      .catch(() => resolve(true));
  });
};
