import { detectIncognito } from 'detectincognitojs';

export const isBrowser = (): boolean => {
  return typeof window !== 'undefined' && typeof window.document !== 'undefined';
};

export const detectBrowser = (
  userAgent = navigator.userAgent,
): { name: TBrowserName; version: number } => {
  let name: TBrowserName = 'Unknown';
  let version = '';

  if (/Firefox\/\d/.test(userAgent)) {
    name = 'Firefox';
    version = userAgent.match(/Firefox\/(\d+)/)?.[1] || '';
  } else if (/OPR\/\d/.test(userAgent)) {
    name = 'Opera';
    version = userAgent.match(/OPR\/(\d+)/)?.[1] || '';
  } else if (/Edg\/\d/.test(userAgent)) {
    // Detects the Chromium based version of Edge
    name = 'Edge';
    version = userAgent.match(/Edg\/(\d+)/)?.[1] || '';
  } else if (/Edge\/\d/.test(userAgent)) {
    // Detects the non-Chromium version of Edge
    name = 'Edge';
    version = userAgent.match(/Edge\/(\d+)/)?.[1] || '';
  } else if (/EdgA\/\d/.test(userAgent)) {
    // Detects the Chromium version of Edge mobile
    name = 'Edge';
    version = userAgent.match(/EdgA\/(\d+)/)?.[1] || '';
  } else if (/UCBrowser\//.test(userAgent)) {
    name = 'Ucbrowser';
    version = userAgent.match(/UCBrowser\/(\d+)/)?.[1] || '';
  } else if (/SamsungBrowser\//.test(userAgent)) {
    name = 'Samsung';
    version = userAgent.match(/SamsungBrowser\/(\d+)/)?.[1] || '';
  } else if (/YaBrowser\//.test(userAgent)) {
    name = 'Yandex';
    version = userAgent.match(/YaBrowser\/(\d+)/)?.[1] || '';
  } else if (/Chrome\//.test(userAgent)) {
    name = 'Chrome';
    version = userAgent.match(/Chrome\/(\d+)/)?.[1] || '';
  } else if (/Version\//.test(userAgent)) {
    name = 'Safari';
    version = userAgent.match(/Version\/(\d+)/)?.[1] || '';
  } else if (/MSIE|Trident/.test(userAgent)) {
    name = 'IE';
    version = userAgent.match(/(?:MSIE |rv:)(\d+)/)?.[1] || '';
  }

  // Remove all characters after the first dot and the characters that follow
  version = version.replace(/[^\d.]/g, '').split('.')[0];

  return { name, version: Number(version) };
};

export const isPrivateBrowser = function (): Promise<boolean> {
  return new Promise(resolve => {
    detectIncognito()
      .then(result => {
        resolve(result.isPrivate);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.debug(e);
        resolve(false);
      });
  });
};

export const detectDeviceType = (userAgent = navigator.userAgent): TDeviceType => {
  // User agent contains 'Mobi', so it's a mobile device
  if (/Mobi/.test(userAgent)) {
    return 'mobile';
  } else {
    return 'desktop';
  }
};

export const getBrowserInfo = (): TBrowserInfo => {
  const { name, version } = detectBrowser();

  return {
    name,
    version,
    availableScreen: `${screen.availWidth}*${screen.availHeight}`,
    device: detectDeviceType(),
    host: location.host,
    href: location.href,
    language: navigator.language,
    pixelDepth: screen.pixelDepth,
    referrer: document.referrer,
    screen: `${screen.width}*${screen.height}`,
    userAgent: navigator.userAgent,
  };
};

export const getChromiumStatus = (): {
  isChromium: boolean;
  version: number;
} => {
  const isChromiumBased = Boolean((<any>window).chrome);
  const versionMatch = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

  if (isChromiumBased && versionMatch) {
    return {
      isChromium: true,
      version: Number(versionMatch[2]),
    };
  }

  return {
    isChromium: false,
    version: 0,
  };
};
