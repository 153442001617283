export const storageName = 'PushEngageSDK';
export const optInTypes: Record<
  | 'bell'
  | 'floatingBar'
  | 'largeSafari'
  | 'largeSafariWithSegments'
  | 'safari'
  | 'singleStep'
  | 'sleekOptInBox',
  TOptInType
> = {
  bell: 3,
  floatingBar: 2,
  largeSafari: 6,
  largeSafariWithSegments: 8,
  safari: 1,
  singleStep: 4,
  sleekOptInBox: 9,
};
export const widgetOptInType = 127;
export const defaultServiceWorkerSetting = {
  scope: true,
  workerStatus: true,
  worker: 'service-worker.js',
};
export const bellIcon = /* SVG */ `<svg width="1em" height="1em" viewBox="0 0 18 22" fill="#4642E5" xmlns="http://www.w3.org/2000/svg">
<path d="M9.0528 0.00546861C9.10255 0.0273431 9.14677 0.0546861 9.19652 0.0765606C9.52267 0.229682 9.71062 0.481238 9.7272 0.842167C9.73826 1.1156 9.73273 1.38903 9.7272 1.66246C9.7272 1.7773 9.76037 1.82652 9.88198 1.84839C12.458 2.31322 14.3762 4.14521 14.9068 6.67171C14.9953 7.10373 15.0174 7.55216 15.0285 7.99511C15.0506 8.91931 15.0119 9.84351 15.0672 10.7622C15.1667 12.3317 15.8466 13.6442 17.0296 14.6996C17.3723 15.0004 17.6321 15.3395 17.6708 15.8043C17.7537 16.7285 17.107 17.4503 16.1672 17.4996C16.1119 17.505 16.0567 17.4996 16.0014 17.4996C11.2308 17.4996 6.45468 17.4996 1.6841 17.4996C1.13131 17.4996 0.666963 17.33 0.324232 16.8925C-0.189863 16.2418 -0.0848327 15.3449 0.584044 14.7653C1.17 14.2567 1.63987 13.6715 1.99919 12.988C2.45248 12.1239 2.65701 11.1943 2.65701 10.2263C2.65701 9.38414 2.64595 8.54744 2.66254 7.70528C2.71782 4.82879 4.90134 2.32416 7.77032 1.85386C7.93063 1.82652 7.97485 1.76636 7.96933 1.61871C7.95827 1.45465 7.98038 1.29059 7.9638 1.12653C7.91405 0.585142 8.11858 0.202339 8.6382 0C8.7764 0.00546861 8.9146 0.00546861 9.0528 0.00546861Z"/>
<path d="M8.47234 21.0049C8.06881 20.8737 7.64316 20.7862 7.27279 20.5948C6.40491 20.1518 5.87423 19.4245 5.61994 18.4948C5.61441 18.4675 5.61441 18.4401 5.60889 18.3964C7.76477 18.3964 9.91513 18.3964 12.0821 18.3964C12.0102 18.7901 11.8554 19.1511 11.6343 19.4847C11.0815 20.3159 10.3076 20.819 9.30706 20.9721C9.27389 20.9776 9.24625 20.994 9.21308 21.0049C8.96985 21.0049 8.7211 21.0049 8.47234 21.0049Z"/>
</svg>`;

export const closeIcon = /* SVG */ `<svg width="1em" height="1em" viewBox="0 0 11 11" fill="#2A2A2A" xmlns="http://www.w3.org/2000/svg">
<path d="M10.4583 1.54041L9.45959 0.541664L5.5 4.50125L1.54042 0.541664L0.541672 1.54041L4.50126 5.5L0.541672 9.45958L1.54042 10.4583L5.5 6.49875L9.45959 10.4583L10.4583 9.45958L6.49875 5.5L10.4583 1.54041Z" />
</svg>`;

export const bellSettingIcon = /* SVG */ `<svg width="1em" height="1em" viewBox="0 0 24 24" fill="#2A2A2A"><path d="M19 12C15.13 12 12 15.13 12 19C12 19.34 12.03 19.67 12.08 20H3V19L5 17V11C5 7.9 7.03 5.17 10 4.29V4C10 2.9 10.9 2 12 2S14 2.9 14 4V4.29C16.97 5.17 19 7.9 19 11V12M10 21C10 22.11 10.9 23 12 23C12.39 23 12.75 22.88 13.06 22.69C12.74 22.17 12.5 21.6 12.3 21H10M23.77 20.32C23.87 20.39 23.89 20.53 23.83 20.64L22.83 22.37C22.77 22.5 22.64 22.5 22.53 22.5L21.28 21.97C21 22.17 20.75 22.34 20.44 22.47L20.25 23.79C20.23 23.91 20.13 24 20 24H18C17.88 24 17.77 23.91 17.75 23.79L17.57 22.47C17.25 22.34 17 22.17 16.72 21.97L15.5 22.5C15.37 22.5 15.23 22.5 15.17 22.37L14.17 20.64C14.11 20.53 14.14 20.39 14.23 20.32L15.29 19.5C15.27 19.33 15.25 19.17 15.25 19S15.27 18.67 15.29 18.5L14.23 17.68C14.14 17.61 14.11 17.5 14.17 17.36L15.17 15.64C15.23 15.53 15.37 15.5 15.5 15.53L16.72 16C17 15.83 17.25 15.66 17.57 15.54L17.75 14.21C17.77 14.09 17.88 14 18 14H20C20.13 14 20.23 14.09 20.25 14.21L20.44 15.54C20.75 15.66 21 15.83 21.28 16L22.53 15.53C22.64 15.5 22.77 15.53 22.83 15.64L23.83 17.36C23.89 17.5 23.87 17.61 23.77 17.68L22.72 18.5C22.74 18.67 22.75 18.84 22.75 19S22.74 19.33 22.72 19.5L23.77 20.32M20.75 19C20.75 18.03 19.97 17.25 19 17.25S17.25 18.03 17.25 19 18.04 20.75 19 20.75 20.75 19.97 20.75 19Z" /></svg>`;

export const bellBadgeIcon = /* SVG */ `<svg width="1em" height="1em" viewBox="0 0 24 24" fill="#2A2A2A"><path d="M21 6.5C21 8.43 19.43 10 17.5 10S14 8.43 14 6.5 15.57 3 17.5 3 21 4.57 21 6.5M19 11.79C18.5 11.92 18 12 17.5 12C14.47 12 12 9.53 12 6.5C12 5.03 12.58 3.7 13.5 2.71C13.15 2.28 12.61 2 12 2C10.9 2 10 2.9 10 4V4.29C7.03 5.17 5 7.9 5 11V17L3 19V20H21V19L19 17V11.79M12 23C13.11 23 14 22.11 14 21H10C10 22.11 10.9 23 12 23Z" /></svg>`;

export const bellRingIcon = /* SVG */ `<svg width="1em" height="1em" viewBox="0 0 24 24" fill="#2A2A2A"><path d="M21,19V20H3V19L5,17V11C5,7.9 7.03,5.17 10,4.29C10,4.19 10,4.1 10,4A2,2 0 0,1 12,2A2,2 0 0,1 14,4C14,4.1 14,4.19 14,4.29C16.97,5.17 19,7.9 19,11V17L21,19M14,21A2,2 0 0,1 12,23A2,2 0 0,1 10,21M19.75,3.19L18.33,4.61C20.04,6.3 21,8.6 21,11H23C23,8.07 21.84,5.25 19.75,3.19M1,11H3C3,8.6 3.96,6.3 5.67,4.61L4.25,3.19C2.16,5.25 1,8.07 1,11Z" /></svg>`;

export const bellCircleIcon = /* SVG */ `<svg width="1em" height="1em" viewBox="0 0 24 24" fill="#2A2A2A"><path d="M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M17,16V15L16,14V11.39C16,10.3 15.73,9.34 15.21,8.53C14.7,7.72 13.96,7.21 13,7V6.5A1,1 0 0,0 12,5.5A1,1 0 0,0 11,6.5V7C10.04,7.21 9.3,7.72 8.79,8.53C8.27,9.34 8,10.3 8,11.39V14L7,15V16H17M13.5,17H10.5A1.5,1.5 0 0,0 12,18.5A1.5,1.5 0 0,0 13.5,17Z" /></svg>`;

export const bellCheckIcon = /* SVG */ `<svg width="1em" height="1em" viewBox="0 0 24 24" fill="#2A2A2A"><path d="M17.75 21.16L15 18.16L16.16 17L17.75 18.59L21.34 15L22.5 16.41L17.75 21.16M3 20V19L5 17V11C5 7.9 7.03 5.18 10 4.29V4C10 2.9 10.9 2 12 2C13.11 2 14 2.9 14 4V4.29C16.97 5.18 19 7.9 19 11V12.08L18 12C14.69 12 12 14.69 12 18C12 18.7 12.12 19.37 12.34 20H3M12 23C10.9 23 10 22.11 10 21H12.8C13.04 21.41 13.33 21.79 13.65 22.13C13.29 22.66 12.69 23 12 23Z" /></svg>`;

export const bellPlusIcon = /* SVG */ `<svg width="1em" height="1em" viewBox="0 0 24 24" fill="#2A2A2A"><path d="M17 14V17H14V19H17V22H19V19H22V17H19V14M12 2A2 2 0 0 0 10 4A2 2 0 0 0 10 4.29C7.12 5.14 5 7.82 5 11V17L3 19V20H12.35A6 6 0 0 1 12 18A6 6 0 0 1 18 12A6 6 0 0 1 19 12.09V11C19 7.82 16.88 5.14 14 4.29A2 2 0 0 0 14 4A2 2 0 0 0 12 2M10 21A2 2 0 0 0 12 23A2 2 0 0 0 13.65 22.13A6 6 0 0 1 12.81 21Z" /></svg>`;

export const loadingIcon = /* SVG */ `<svg viewBox="0 0 1024 1024" focusable="false" width="1em" height="1em" fill="currentColor"><path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path></svg>`;

export const defaultNotificationTag = 'welcome_notification';
export const defaultNotificationTitle = 'Click to see updates';
export const pushEngageHomePageUrl = 'https://www.pushengage.com/';
export const poweredByReferralUrl = `${pushEngageHomePageUrl}?utm_medium=referral&utm_campaign=powered_by&utm_source=pushengage_sdk`;
export const defaultMinStorageDuration = 7; // 7 days

export const database = {
  name: 'PushEngageSDK',
  stores: [
    /**
     * Store the value based on the ID. The ID can be unique and can represent
     * anything.
     */
    { name: 'ids', keyPath: 'id' },
    // Store the tags of failed view notifications to retry later.
    {
      name: 'failedViewedNotificationTags',
      keyPath: 'id',
    },
    // Store the tags of failed click notifications to retry later.
    {
      name: 'failedClickedNotificationTags',
      keyPath: 'id',
    },
  ],
  version: 1,
};

export const workerMessengerCommand = {
  // Used to request the current subscription state.
  ampSubscriptionState: 'amp-web-push-subscription-state',
  /**
   * Used to request the service worker to subscribe the user to push.
   * Notification permissions are already granted at this point.
   */
  ampSubscribe: 'amp-web-push-subscribe',
  // Used to unsusbcribe the user from push.
  ampUnsubscribe: 'amp-web-push-unsubscribe',
} as const;

export const env = buildDefines;

export const quieterUIPermissionImage = {
  firefox: `${env.__STATIC_STORAGE_ENDPOINT__}/react-dashboard/design/reminder-firefox.png`,
  chrome: `${env.__STATIC_STORAGE_ENDPOINT__}/react-dashboard/design/reminder-chrome.png`,
};

export const brandName = 'PushEngage';

/**
 * The sites collects subscribers on its own domain due to the added
 * service worker in the root directory. However, this service worker can only
 * be accessed without query parameters. Our current SDK started adding query
 * parameters such as app ID and version to the service worker path during
 * registration, causing it to fail. In contrast, our old web SDK did not add
 * query parameters, which is why it was working. We are in the process of
 * fixing this issue.
 */
export const skipQueryParamsInWorkerPathSiteIds = [60413];

export const defaultSubscriptionManagementWidgetSetting = {
  enabled: false,
  title: 'Push Notification Settings',
  modal_background_color: '#ffffff',
  modal_text_color: '#0d0d0d',
  allow_text: 'Show Notifications',
  on_switch_color: '#4642E5',
  off_switch_color: '#BFBFBF',
  trigger_button: {
    enabled: true,
    size: 'default',
    position_x: 'right',
    position_y: 'bottom',
    offset_top: 100,
    offset_bottom: 100,
    icon_background_color: '#ffffff',
    icon_color: '#4642E5',
    icon_type: 'default',
    z_index: 2147483640,
    rules: {
      enabled: false,
      exclude_countries: [],
      exclude_devices: [],
      exclude: {},
      include_countries: [],
      include_devices: [],
      include: {},
    },
  },
  segment_preference: {
    enabled: false,
    subscribed_title: 'Subscribed Segments',
    exclude_subscribed_segments: [],
    show_all_subscribed_segment: false,
    title: 'Recommended Segments',
    segments: [],
    checkbox_background_color: '#ACB7C8',
    checkbox_tick_color: '#475FDE',
    default_segment_selection: true,
  },
  unsubscribe_options: {
    enabled: false,
    confirm_message: 'Are you sure? You will miss out on all our updates!',
    ok_text: 'Yes',
    cancel_text: 'No',
    ok_button_background_color: '#475FDE',
    ok_button_text_color: '#ffffff',
    cancel_button_background_color: '#ffffff',
    cancel_button_text_color: '#677489',
  },
  personal_notification_options: {
    enabled: false,
    label: 'Allow Personalized Notifications',
  },
};
