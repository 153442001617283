import { detectBrowser, detectDeviceType, getChromiumStatus } from '../libraries/browser.library';
import { detectSiteType } from '../libraries/url.library';
import { hasOwn } from '../libraries/app.library';

export const hasSupportForPushApiInSafari = (): boolean => {
  const { name } = detectBrowser();

  return (
    name === 'Safari' &&
    'serviceWorker' in navigator &&
    'Notification' in window &&
    'PushManager' in window
  );
};

export const safariWebPushSupported = (): boolean => {
  const { name, version } = detectBrowser();

  /**
   * Our web push package does not support Safari versions prior to 10
   * Additionally, versions 7, 8, and 9 are not supported by our JS script,
   * so we are excluding them from our compatibility checks.
   */

  return (
    name === 'Safari' &&
    version >= 11 &&
    'Notification' in window &&
    'safari' in window &&
    'pushNotification' in (window as any).safari
  );
};

export const supportsVapidPush = (): boolean => {
  const { name, version } = detectBrowser();
  const deviceType = detectDeviceType();
  const siteType = detectSiteType();

  /**
   * The Push API is not supported in UCBrowser, Firefox versions 48 and 49,
   * Opera version 41, and Yandex mobile browser due to broken implementations.
   * In UCBrowser, an error "could not connect to push server" occurs, while
   * Firefox and Opera encounter an error "Error retrieving push subscription".
   * Additionally, the Yandex mobile browser always returns a notification
   * permission of "denied". As a result, these browsers should be excluded
   * from using the Push API
   */
  const isUnsupportedBrowser =
    name === 'Ucbrowser' ||
    (name === 'Firefox' && (version === 48 || version === 49)) ||
    (name === 'Opera' && version === 41) ||
    (name === 'Yandex' && deviceType === 'mobile');

  if (isUnsupportedBrowser) {
    return false;
  }

  /**
   * When the site URL protocol is HTTP, Firefox-based browsers do not expose the
   * 'PushManager' and 'PushSubscriptionOptions' in the window object. However,
   * starting from version 50, the 'PushSubscriptionOptions' become available.
   */
  const isHttpOnFirefoxBrowser = siteType === 'http' && name === 'Firefox' && version >= 50;

  if (isHttpOnFirefoxBrowser) {
    return true;
  }

  return (
    'Notification' in window &&
    'PushManager' in window &&
    typeof PushSubscriptionOptions !== 'undefined' &&
    hasOwn(PushSubscriptionOptions.prototype, 'applicationServerKey')
  );
};

export const isPushNotificationSupported = (): boolean => {
  return supportsVapidPush() || safariWebPushSupported();
};

export const isSupportQuieterUI = (): boolean => {
  const { name, version } = detectBrowser();
  const deviceType = detectDeviceType();
  const chromiumStatus = getChromiumStatus();

  return (
    (chromiumStatus.isChromium && chromiumStatus.version >= 80) ||
    (deviceType === 'desktop' && name === 'Firefox' && version >= 72)
  );
};

export const isUserGestureRequiredForPermissionPrompt = () => {
  const { name, version } = detectBrowser();

  return (name === 'Firefox' && version >= 72) || name === 'Safari' || name === 'Yandex';
};

/**
 * NOTE: Make sure that when you are using this function, you use the function
 * directly. This is because notification permissions can change in the browser
 * based on user interaction, so avoid storing them in the global scope and use
 * them directly.
 */
export const getNotificationPermission = ({
  safariWebPushId,
}: {
  safariWebPushId?: string;
}): NotificationPermission => {
  const { name } = detectBrowser();

  /**
   * We are currently checking the Safari object, even though we don't need
   * to. However, based on our logs, there are instances where the Safari
   * object is unavailable, causing issues with obtaining permissions.
   */
  if (
    safariWebPushId &&
    name === 'Safari' &&
    (<any>window).safari &&
    !hasSupportForPushApiInSafari()
  ) {
    const safariPermission: TSafariPermissionData = (<any>(
      window
    )).safari.pushNotification.permission(safariWebPushId);

    return safariPermission.permission;
  }

  return Notification.permission;
};

export const isTokenRefresh = ({ safariWebPushId }: { safariWebPushId?: string }) => {
  const permission = getNotificationPermission({
    safariWebPushId,
  });

  return permission === 'granted';
};
