import { logDebug } from './helper';

export const noop = () => {
  // Do nothing
};

export const hasOwn = (obj: any, key: string) => {
  return Object.prototype.hasOwnProperty.call(obj, key);
};

export const safeParse = <T>(jsonString: string): T | null => {
  let parseData = null;

  try {
    parseData = JSON.parse(jsonString) as T;
  } catch (e) {
    logDebug(e);
  }

  return parseData;
};

export const safeStringify = (objectData: object): string | null => {
  let jsonString = null;

  try {
    jsonString = JSON.stringify(objectData);
  } catch (e) {
    logDebug(e);
  }

  return jsonString;
};

export const removeArrayItem = (array: Array<string | number>, item: string | number) => {
  const index = array.indexOf(item);

  if (index > -1) {
    array.splice(index, 1);
  }

  return array;
};

export const isObject = (value: any) => {
  return Object.prototype.toString.call(value) === '[object Object]';
};

export const get = <T, K extends string | (string | number)[]>(
  object: T,
  path: K,
  defaultValue?: unknown,
): K extends Array<infer R>
  ? R extends keyof T
    ? T[R]
    : unknown
  : K extends keyof T
  ? T[K]
  : unknown => {
  const keys = Array.isArray(path) ? path : path.split('.');
  let value: any = object;

  for (const key of keys) {
    if (value == null) {
      return defaultValue as any;
    }

    value = value[key];
  }

  return value === undefined ? (defaultValue as any) : value;
};

export const omit = <T extends Record<string, any>>(
  object: T,
  keys: (string | (string | number)[])[],
): Omit<T, keyof T> => {
  const result = { ...object };

  for (const key of keys) {
    const keysArray = Array.isArray(key) ? key : [key];
    let currentObject: any = result;
    let currentKey = keysArray[0];

    for (let i = 0; i < keysArray.length - 1; i++) {
      currentObject = currentObject[currentKey];
      currentKey = keysArray[i + 1];
    }
    delete currentObject[currentKey];
  }

  return result;
};

export const isEmptyObject = (obj: any): boolean => {
  if (!isObject(obj)) {
    return true;
  }

  return Object.keys(obj).length === 0;
};

export const isEmptyArray = (array: any): boolean => {
  if (!Array.isArray(array)) {
    return true;
  }

  return array.length === 0;
};

export const addScriptToPage = (...scripts: string[]): void => {
  scripts.forEach(script => {
    try {
      // If the URL is valid, create a script element and add it to the head
      const scriptElement = document.createElement('script');

      scriptElement.src = script;
      scriptElement.async = true;
      scriptElement.type = 'text/javascript';
      document.head.appendChild(scriptElement);
    } catch (error) {
      // If the URL is not valid, log an error
      throw new Error(`Invalid script URL: ${script}`);
    }
  });
};

export const addStyleToPage = (...styles: string[]): void => {
  styles.forEach(style => {
    try {
      // If the URL is valid, create a style element and add it to the head
      const linkElement = document.createElement('link');

      linkElement.rel = 'stylesheet';
      linkElement.href = style;
      linkElement.type = 'text/css';
      document.head.appendChild(linkElement);
    } catch (error) {
      // If the URL is not valid, log an error
      throw new Error(`Invalid style URL: ${style}`);
    }
  });
};

export const toInteger = (value: any) => {
  if (typeof value !== 'string' && typeof value !== 'number') {
    return 0;
  }

  value = String(value).trim();

  const parsedValue = parseInt(value, 10);

  if (isNaN(parsedValue)) {
    return 0;
  }

  return parsedValue;
};

export const isWorker = () => {
  return self instanceof ServiceWorkerGlobalScope;
};

export const isUndefined = (value: any) => {
  return value === undefined;
};

export const isNull = (value: any) => {
  return value === null;
};

export const uniqueArray = <T>(array: T[]): T[] => {
  return Array.from(new Set(array));
};

export const capitalizeFirstLetter = (sentence: string): string => {
  if (!sentence) return sentence;

  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
};

export const delay = (ms: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const convertStringToArray = (value: string, separator = ','): Array<string | number> => {
  return value.split(separator);
};

export const keepLastMaxElements = <T>(arr: T[], size: number): T[] => {
  return arr.length > size ? arr.slice(-size) : arr;
};

export const replaceLastNumberFromIpToZero = (isEu: boolean, ip?: string) => {
  if (!isEu || !ip) {
    return ip;
  }

  const splitChar = ip.includes('.') ? '.' : ':';

  const ipParts = ip.split(splitChar);

  ipParts[ipParts.length - 1] = '0';

  return ipParts.join(splitChar);
};

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number,
): (...args: Parameters<T>) => void {
  let timeout: number | null = null;

  return (...args: Parameters<T>) => {
    const later = () => {
      timeout = null;

      func(...args);
    };

    if (timeout !== null) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(later, wait) as unknown as number;
  };
}
