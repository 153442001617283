import { handlePushEvent, isPushNotificationSupported } from './_helper.manager';
import log from '../../libraries/log.library';

type PushEngageMethod = (...args: any[]) => any;
interface PushEngageType {
  [key: string]: PushEngageMethod;
}
type HandlerGetReturn = PushEngageMethod | (() => Promise<never>);

/**
 * NOTE: This method handles errors that occur when SDK methods are not
 * exposed. Such issues arise due to errors during SDK initialization,
 * such as in cases of private browsing, unsupported push notifications,
 * or invalid SDK initialization options.
 */
export const handleSDKMethodErrors = ({
  errorMessage,
  commands,
  legacyCommands,
}: {
  errorMessage: string;
  commands: any;
  legacyCommands: any;
}) => {
  /**
   * Older browsers do not support 'Proxy', but this isn't a major issue.
   * All browsers that support the 'Push API' also support 'Proxy'
   */
  if (typeof Proxy === 'undefined') {
    return;
  }

  // Sdk API methods
  const PushEngage = {
    push: handlePushEvent,
    isPushNotificationSupported: () => isPushNotificationSupported(),
  };

  const handler: ProxyHandler<PushEngageType> = {
    get(target: PushEngageType, property: string | symbol): HandlerGetReturn {
      if (typeof target[property as string] === 'function') {
        return target[property as string] as PushEngageMethod;
      } else {
        return function () {
          return Promise.reject(new Error(errorMessage));
        };
      }
    },
  };

  const PushEngageProxy: PushEngageType = new Proxy(PushEngage, handler);

  (<any>window).PushEngage = Object.freeze(PushEngageProxy);

  if (commands && Array.isArray(commands)) {
    handlePushEvent(...commands);
  }

  // Legacy API methods for backward compatibility from v2.0.0
  const legacyHandler = (errorMessage: string, ...args: any[]) => {
    args.forEach(event => {
      if (Array.isArray(event)) {
        const fn = event.find(e => typeof e === 'function');

        if (fn) {
          /**
           * If we don't handle errors with a try-catch here, it will throw an error.
           * This can disrupt the execution of other SDK sequence methods because
           * the error handling depends on the customer's implementation, and it's
           * methods exposed to the customer.
           */
          try {
            fn({ statuscode: 0, message: errorMessage });
          } catch (error) {
            log.error(error);
          }
        }
      }
    });
  };

  window._peq = Object.freeze({
    push: legacyHandler.bind(null, errorMessage),
  });

  if (legacyCommands && Array.isArray(legacyCommands)) {
    legacyHandler(errorMessage, ...legacyCommands);
  }

  // Legacy API methods for backward compatibility from v1.0.0
  const legacyV1Handler: ProxyHandler<PushEngageType> = {
    get(): any {
      return function (...args: any[]) {
        // Check if any of the arguments is a function
        const fn = args.find(e => typeof e === 'function');

        if (fn) {
          return fn({ statuscode: 0, message: errorMessage });
        }
      };
    },
  };

  (<any>window)._pe = Object.freeze(new Proxy({}, legacyV1Handler));
};
