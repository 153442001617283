import { sendHttpRequest } from '../libraries/httpRequest.library';
import { detectBrowser } from '../libraries/browser.library';
import log from '../libraries/log.library';
import { env } from '../config/constants.config';
import AppException from '../exceptions/App.exception';

/**
 * NOTE: This function wil never reject.
 */
export const sendErrorLog = async (
  appName: 'service-worker' | 'sdk' = 'sdk',
  error: TAppException,
  extraDetails?: {
    [key: string]: any;
  },
): Promise<void> => {
  try {
    log.debug(error, (<TAppException>error).details);

    // Ignore send error log to server
    const ignoreErrorNames = [
      AppException.ERROR_NAME.InvalidAppId,
      AppException.ERROR_NAME.UnsubscribedFailedFromWorker,
      AppException.ERROR_NAME.NotPushEngagePayload,
      AppException.ERROR_NAME.PayloadLessInvalidNotificationPayload,
      AppException.ERROR_NAME.InvalidNotificationPayload,
      AppException.ERROR_NAME.PushPayloadParseError,
      AppException.ERROR_NAME.OfflineError,
      AppException.ERROR_NAME.OpenNotificationUrlFailed,
      AppException.ERROR_NAME.SendOptInAnalyticsError,
      /**
       * The QuotaExceededError in IndexedDB typically indicates that you've
       * exceeded the storage quota available for your site or application in
       * the user's browser. This error name is thrown by the browser itself.
       */
      AppException.ERROR_NAME.QuotaExceededError,
      AppException.ERROR_NAME.DisabledPushSubscriptionError,
      /**
       * This error is generated by the browser itself, often when working with
       * browser APIs or the IndexedDB API. We cannot handle this error, so we
       * choose not to send it to the server.
       */
      AppException.ERROR_NAME.UnknownError,
      AppException.ERROR_NAME.SDKInitOptionsNotFound,
      /**
       * These NS_ errors are thrown when attempting to access local storage, session storage,
       * or any native API that is not initialized properly. We cannot handle this error, so we are
       * choosing to ignore sending it to the server.
       */
      'NS_ERROR_FILE_CORRUPTED',
      'NS_ERROR_STORAGE_CONSTRAINT',
      'NS_ERROR_FILE_NO_DEVICE_SPACE',
      'NS_ERROR_STORAGE_IOERR',
      'NS_ERROR_NOT_INITIALIZED',
      AppException.ERROR_NAME.LocalStorageNotAvailable,
      AppException.ERROR_NAME.SubscriptionAlreadyInitialized,
    ];

    const ignoreErrorTypes: any[] = [];

    if (ignoreErrorNames.includes(error.name) || ignoreErrorTypes.includes(error.type || '')) {
      return;
    }

    const url = `${env.__LOG_API_ENDPOINT__}/logs`;

    const body = {
      sdk: env.__SDK_VERSION__,
      bw: detectBrowser(),
      app: appName === 'service-worker' ? 'new-service-worker' : 'web-sdk',
      name: error.name,
      data: (<TAppException>error).details || {},
      message: error.message,
      stacktrace: error.stack?.toString() || new Error().stack?.toString(),
      type: (<TAppException>error).type,
      url: location.href,
      // This should be bottom because some values can be override by extraDetails.
      ...extraDetails,
    };

    await sendHttpRequest(url, {
      method: 'POST',
      body: JSON.stringify(body),
      mode: 'no-cors',
    });
  } catch (e) {
    log.error('Error occurred while sending error logs', e);
  }
};
