import { isPushNotificationSupported } from '../utils/webPush.util';
import { getSDKLoadCount, getSDKInitOptions } from '../utils/app.util';
import log from '../libraries/log.library';
import { isPrivateBrowser } from '../libraries/browser.library';
import { addScriptToPage, addStyleToPage } from '../libraries/app.library';
import { sendErrorLog } from '../api/errorLog.api';
import { handleSDKMethodErrors } from '../managers/sdkManager/sdkError.manager';
import { env } from '../config/constants.config';

(async () => {
  try {
    // Check if SDK is included more than once
    const SDKLoadCount = getSDKLoadCount();

    if (SDKLoadCount > 1) {
      log.warn(
        'Please include our SDK only once on your page for optimal performance as the web push SDK is included more than once.',
      );

      log.warn(
        `The SDK initialization is exiting to prevent errors caused by double initialization, which has occurred ${SDKLoadCount} times.`,
      );

      return;
    }

    const commands = (<any>window).PushEngage;
    const legacyCommands = (<any>window)._peq;
    const SDKInitOptions = getSDKInitOptions(commands, legacyCommands);

    // Possible error conditions
    const errorConditions: Array<{
      check: () => Promise<boolean> | boolean;
      message: string;
      logType: 'warn' | 'error';
    }> = [
      {
        // Check if app init options are provided
        check: () => !SDKInitOptions,
        message: 'Please provide app init options as they are missing.',
        logType: 'error',
      },
      {
        // Check if appId is provided
        check: () => !SDKInitOptions?.appId,
        message: 'Please provide a valid appId.',
        logType: 'error',
      },
      {
        // Check if browser is supported
        check: () => !isPushNotificationSupported(),
        message: 'Your browser does not support push notifications.',
        logType: 'warn',
      },
      {
        // Check if browser is in private mode
        check: async () => await isPrivateBrowser(),
        message:
          'Your browser is in private mode. Push subscription will not work in private mode.',
        logType: 'warn',
      },
    ];

    for (const errorCondition of errorConditions) {
      const isErrorConditionMet = await errorCondition.check();

      if (isErrorConditionMet) {
        const { logType, message } = errorCondition;

        log[logType](message);

        handleSDKMethodErrors({ commands, legacyCommands, errorMessage: message });

        return;
      }
    }

    window.__PushEngageSDKInitOptions = SDKInitOptions;

    // Import PushEngage subscription script
    addScriptToPage(
      `${env.__CDN_SERVER_HOST__}/sdks/pushengage-subscription.js?v=${env.__SDK_VERSION__}`,
    );
    addStyleToPage(
      `${env.__CDN_SERVER_HOST__}/sdks/pushengage-sdk-style.css?v=${env.__SDK_VERSION__}`,
    );
  } catch (error: any) {
    log.error(error.message);
    sendErrorLog('sdk', error, {
      url: location.href,
    });
  }
})();
