export const getUrlObject = (url: string): URL | null => {
  const isValidURLConstructor = (object: any): object is typeof URL => {
    if (typeof object !== 'function' || object.name !== 'URL') {
      return false;
    }

    // Create a temporary URL object to check for the necessary properties
    let tempUrl;

    try {
      tempUrl = new object('http://example.com');
    } catch {
      return false;
    }

    return (
      'search' in tempUrl && 'searchParams' in tempUrl && 'origin' in tempUrl && 'host' in tempUrl
    );
  };

  if (isValidURLConstructor(URL)) {
    try {
      return new URL(url);
    } catch (error) {
      return null;
    }
  } else {
    try {
      // If the global URL has been overridden, retrieve the original URL using an iframe
      const iframe = document.createElement('iframe');

      document.body.appendChild(iframe);

      const iframeURLConstructor = (<any>iframe.contentWindow)?.URL;

      document.body.removeChild(iframe); // clean up the iframe

      if (isValidURLConstructor(iframeURLConstructor)) {
        return new iframeURLConstructor(url);
      }

      return null;
    } catch (error) {
      return null;
    }
  }
};

export const getValueFromUrlByKey = (key: string, url: string) => {
  try {
    const urlObj = getUrlObject(url);

    if (!urlObj) {
      return undefined;
    }

    const searchParams = new URLSearchParams(urlObj.search);
    const value = searchParams.get(key);

    if (value) {
      return value;
    } else {
      return undefined;
    }
  } catch (error) {
    return undefined;
  }
};

// Support multi language url
export const safeDecodeURIComponent = (uri: any, mod = 0) => {
  const newUri = String(uri);
  const arr = newUri.split(/(%(?:d0|d1)%.{2})/);
  let out = '';

  for (let i = 0, l = arr.length; i < l; i++) {
    let x;

    try {
      x = decodeURIComponent(arr[i]);
    } catch (e) {
      x = mod ? arr[i].replace(/%(?!\d+)/g, '%25') : arr[i];
    }

    out += x;
  }

  return out;
};

export const addQueryParamsToUrl = (url: string, params: { [key: string]: any }) => {
  const urlObj = getUrlObject(url);

  if (!urlObj) {
    return url;
  }

  Object.keys(params).forEach(key => {
    const value = params[key];

    if (typeof value !== 'undefined') {
      urlObj.searchParams.append(key, params[key]);
    }
  });

  return urlObj.toString();
};

export const getQueryParamsFromUrl = (url: string): Record<string, string> => {
  const queryParams: { [key: string]: string } = {};
  const queryStartIndex = url.indexOf('?');

  if (queryStartIndex !== -1) {
    const queryString = url.substring(queryStartIndex + 1);
    const queryPairs = queryString.split('&');

    for (let i = 0; i < queryPairs.length; i++) {
      const pair = queryPairs[i].split('=');
      const key = decodeURIComponent(pair[0]);
      const value = decodeURIComponent(pair[1] || '');

      queryParams[key] = value;
    }
  }

  return queryParams;
};

export const isLocalhost = () => {
  const hostname = location.hostname;

  return Boolean(
    hostname === 'localhost' ||
      hostname === '[::1]' ||
      hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
  );
};

export const detectProtocol = (url?: string): 'http' | 'https' => {
  if (!url) {
    return <'http' | 'https'>location.protocol.slice(0, -1);
  }

  const match = url.match(/^(\w+):\/\//);

  return match ? <'http' | 'https'>match[1] : 'http';
};

export const detectSiteType = () => {
  if (isLocalhost()) {
    return 'https';
  }

  return detectProtocol();
};

export const getSubdomainFromUrl = (url: string): string | null => {
  try {
    const parsedUrl = getUrlObject(url);

    if (!parsedUrl) {
      return null;
    }

    const hostnameParts = parsedUrl.hostname.split('.');

    if (hostnameParts.length >= 3) {
      return hostnameParts[0];
    }

    return null;
  } catch (error) {
    return null;
  }
};
