/* eslint-disable no-console */
import { storageName } from '../config/constants.config';

const log = {
  debug: (...args: any[]) => {
    /**
     * In the context of a service worker, the window is undefined; therefore,
     * debugging is always enabled.
     */
    let isDebug = typeof window === 'undefined';

    /**
     * Sometimes, site owners do not allow the use of session storage due to security reasons,
     * so we need to handle that case.
     */
    if (
      typeof window !== 'undefined' &&
      typeof sessionStorage !== 'undefined' &&
      sessionStorage.getItem(`${storageName}Debug`)
    ) {
      isDebug = true;
    }

    if (isDebug) {
      console.debug('PushEngage:', ...args);
    }
  },

  info: (...args: any[]) => {
    console.info('PushEngage:', ...args);
  },

  warn: (...args: any[]) => {
    console.warn('PushEngage:', ...args);
  },

  error: (...args: any[]) => {
    console.error('PushEngage:', ...args);
  },
};

export default log;
