import AppException from '../exceptions/App.exception';

const delay = (ms: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

type TRequestOptions = RequestInit & {
  // Duration in ms to wait before retrying the request again if it fails
  retryDelay?: number;
  // Number of retries to attempt if the request fails
  retries?: number;
  // List of stats code to skip the retry
  skipRetryStatusCodes?: number[];
};

export const sendHttpRequest = async (
  url: string,
  options: TRequestOptions = {},
): Promise<Response> => {
  const {
    retryDelay = 1000,
    retries = 0,
    skipRetryStatusCodes = [400, 404, 403, 422],
    ...fetchOptions
  } = options;

  if (fetchOptions.body) {
    if (!fetchOptions.headers) {
      fetchOptions.headers = { 'Content-Type': 'application/json' };
    }
  }

  let response: Response | undefined = undefined;
  let retryCount = 0;

  while (retryCount < retries + 1) {
    const retryDelayInSec = retryDelay * (retryCount + 1);

    try {
      response = await fetch(url, fetchOptions);

      if (response.ok || retryCount >= retries) {
        /**
         * If the response is successful or we've reached max retries, break
         * out of the loop
         */
        break;
      }

      if (skipRetryStatusCodes.includes(response.status)) {
        /**
         * If the status code is in skipRetryStatusCodes, break out of the
         * loop without retrying
         */
        break;
      }

      // Delay before retrying
      await delay(retryDelayInSec);
    } catch (error: any) {
      if (retryCount >= retries) {
        // Check for online status
        if (
          error.name === 'TypeError' &&
          error.message === 'Failed to fetch' &&
          !navigator.onLine
        ) {
          throw new AppException({
            message: 'Browser is offline. Request not sent.',
            name: AppException.ERROR_NAME.OfflineError,
            details: { fetchOptions, requestUrl: url },
          });
        }

        // If we've reached max retries, throw the error
        throw new AppException({
          message: error.message,
          name: error.name || AppException.ERROR_NAME.HttpRequestError,
          details: { fetchOptions, requestUrl: url },
        });
      }

      // Delay before retrying
      await delay(retryDelayInSec);
    }

    retryCount++;
  }

  if (!response) {
    throw new AppException({
      message: 'Request failed and no response was received.',
      name: AppException.ERROR_NAME.HttpRequestError,
      details: { fetchOptions, requestUrl: url },
    });
  }

  return response;
};
