class AppException extends Error implements TAppException {
  static readonly ERROR_NAME = {
    InvalidAppId: 'InvalidAppId',
    UnsubscribedFailedFromWorker: 'UnsubscribedFailedFromWorker',
    NotPushEngagePayload: 'NotPushEngagePayload',
    PayloadLessInvalidNotificationPayload: 'PayloadLessInvalidNotificationPayload',
    InvalidNotificationPayload: 'InvalidNotificationPayload',
    PushPayloadParseError: 'PushPayloadParseError',
    OfflineError: 'OfflineError',
    OpenNotificationUrlFailed: 'OpenNotificationUrlFailed',
    SendOptInAnalyticsError: 'SendOptInAnalyticsError',
    QuotaExceededError: 'QuotaExceededError',
    DisabledPushSubscriptionError: 'DisabledPushSubscriptionError',
    IndexedDBOpenError: 'IndexedDBOpenError',
    UnknownError: 'UnknownError',
    PayloadLessFetchError: 'PayloadLessFetchError',
    PayloadLessParseError: 'PayloadLessParseError',
    PushPayloadFetchError: 'PushPayloadFetchError',
    NotificationParseError: 'NotificationParseError',
    RequiredSettingsMissing: 'RequiredSettingsMissing',
    SiteDataMissing: 'SiteDataMissing',
    HttpRequestError: 'HttpRequestError',
    UserNotSubscribed: 'UserNotSubscribed',
    ServiceWorkerNotFound: 'ServiceWorkerNotFound',
    ServiceWorkerNotRegistering: 'ServiceWorkerNotRegistering',
    SDKInitOptionsNotFound: 'SDKInitOptionsNotFound',
    RequestPermissionNotSupported: 'RequestPermissionNotSupported',
    SubscriberAdditionServerError: 'SubscriberAdditionServerError',
    SubscriberHashNotAvailableServerError: 'SubscriberHashNotAvailableServerError',
    LocalStorageNotAvailable: 'LocalStorageNotAvailable',
    SubscriptionAlreadyInitialized: 'SubscriptionAlreadyInitialized',
  };

  static readonly ERROR_TYPE = {
    IndexedDBError: 'IndexedDBError',
    DexterServerError: 'DexterServerError',
  };

  static readonly ERROR_MESSAGE = {
    InvalidAppId: 'AppId is not defined.',
    UserNotSubscribed: 'User is not subscribed.',
  };

  details: Record<string, any>;
  type: string;

  constructor({
    message,
    name = 'AppError',
    details = {},
    type = 'Error',
  }: {
    message: string;
    name?: string;
    details?: Record<string, any>;
    type?: string;
  }) {
    super(message);
    this.details = details;
    this.name = name;
    this.type = type;
  }
}

export default AppException;
